

















































































import { Component } from 'vue-property-decorator'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { OverlayView } from '@/views/mixins/OverlayView'
import Fact from '@/components/facts/Fact.vue'
import constants from '@/constants'
import { Getter } from 'vuex-class'
import { FactDescription } from '@/interfaces/engine-exports'
import { Auth } from '@/services/Auth'
import Warning from '@/components/Warning.vue'
import ImportantInfo from '@/components/ImportantInfo.vue'
import { OverlayGroup } from '@uncharted/coverhub-framework'
import { FnOverlayGroupByName } from '@/store/modules/product/getters'
import { Shift } from '@/services/Shift'
import { ACTION_VALIDATE_PRODUCT } from '@/store/modules/product/actionTypes'
import NextButton from '@/components/layout/NextButton.vue'
import { saleJourneyActionHandler, saleJourneyRouteGuard } from '@/services/SaleJourneyService'

@Component({
  components: {
    Fact,
    Warning,
    ImportantInfo
  },
  beforeRouteLeave(to, from, next) {
    // prevent navigate to next page if invalid
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that: any = this
    if (to.name === constants.routeNames.APPLICATION_SUMMARY && !that.valid) {
      return false
    } else {
      next()
    }
  }
})
export default class ApplicationForm extends OverlayView {
  @Getter('user/email')
  private email!: string

  @Getter('product/getOverlayGroupByName')
  private getOverlayGroupByName!: FnOverlayGroupByName

  private prevRouteName = constants.routeNames.APPLICATION_CERT
  private overlayGroup!: OverlayGroup
  private mappedFacts: any = {}
  private headingKeys = [
    'appForm.heading.name',
    'appForm.heading.mainUnitPhoto'
  ]

  constructor() {
    super('applicant')
  }

  async next() {
    const next = this.$refs?.next as NextButton

    await this.$store.dispatch(ACTION_VALIDATE_PRODUCT)
    if (!this.valid) {
      return
    }

    this.setFactValue({
      id: 'applicant.email',
      value: this.email
    })

    const deviceSerialNumber =
      this.overlayGroup.overlays[0]
        .getProducts()[0]
        .describeFacts()
        .components[0].facts
        .find((f) => f.id === 'COMPONENT:base.deviceregistration.photofront.serialbody')?.currentValue

    if (!deviceSerialNumber) {
      throw Error('Somehow form was valid but device serial number not present.')
    }

    if (await Shift.inactivePolicyExistsForDevice(deviceSerialNumber)) {
      const msg = this.$t('common.error.sixMonthStandDown').toString()
      this.$showErrorPopUp(msg)

      await this.$router.push({ name: constants.routeNames.HOME })

      return
    }

    if (await Shift.duplicateBodySerialExistsForAccount(deviceSerialNumber)) {
      const msg = this.$t('common.error.duplicateBodySerial').toString()
      this.$showErrorPopUp(msg)
      next.reset()

      return
    }

    if (!await Shift.canAddAdditionalDevice()) {
      const msg = this.$t('common.error.deviceLimitReached').toString()
      this.$showErrorPopUp(msg)

      await this.$router.push({ name: constants.routeNames.HOME })

      return
    }

    // Generate nickname
    const nickFact = this.overlayGroup.overlays[0]
      .getProducts()[0]
      .describeFacts()
      .components[0].facts
      .find((f) => f.id === 'COMPONENT:base.deviceregistration.nickname')

    if (!nickFact?.currentValue) {
      Shift.generateDeviceNickname(this.$t('common.devicePrefix').toString())
        .then((nickname: string) => {
          this.setFactValue({
            id: 'COMPONENT:base.deviceregistration.nickname',
            value: nickname
          })
          this.$router.push({ name: constants.routeNames.APPLICATION_SUMMARY })
        })
        .catch((e) => {
          console.error(e)
          this.$router.push({ name: constants.routeNames.APPLICATION_SUMMARY })
        })
    } else {
      await this.$router.push({ name: constants.routeNames.APPLICATION_SUMMARY })
    }
  }

  getFact(id: string): FactDescription | undefined {
    return this.facts.find((f) => f.id === id)
  }

  getHeading(ix: number) {
    return this.$t(this.headingKeys[ix])
  }

  created() {
    this.doCreated()
    this.overlayGroup = this.getOverlayGroupByName('application')
    if (this.overlayGroup) {
      this.mapFacts()
    }
    if (this.$route.params.skipPoC === 'yes') {
      this.prevRouteName = constants.routeNames.APPLICATION_PLAN
    }
  }

  showSerials(): boolean {
    return !!this.facts.find(
      (f) => f.id === 'COMPONENT:base.deviceregistration.photofront.serialbody'
    )
  }

  isLoggedIn(): boolean {
    return Auth.loggedInAs('otp')
  }

  onFactChange(factChange: FactChangeEvent) {
    this.setFactValue(factChange)
    this.mapFacts()
  }

  mapFacts() {
    this.overlayGroup.overlays.forEach((o) => {
      this.mappedFacts[o.name] = o.describeFacts().allFacts
    })
  }

  mounted() {
    // Sale Journey Route Guard - to handle back button state
    saleJourneyRouteGuard(saleJourneyActionHandler(this.$store.state.product.saleJourneyState), this.$router, this.$store.state.product.invoiceId)
  }
}
